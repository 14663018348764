<div
  class="op-hover-card"
  *ngIf="turboFrameSrc"
>
  <turbo-frame
    #turboFrame
    loading="lazy"
    id="op-hover-card-body"
    [src]="turboFrameSrc">
  </turbo-frame>
</div>
