<div class="op-grid-page">
  <div *ngIf="showToolbar"
       class="toolbar op-grid-page--toolbar-container">
      <div class="op-grid-page--title-container title-container">
        <h2 [textContent]="text.title"></h2>
      </div>

      <ul class="op-grid-page--toolbar-items toolbar-items hidden-for-mobile">
        <li class="toolbar-item hidden-for-tablet">
          <opce-zen-mode-toggle-button></opce-zen-mode-toggle-button>
        </li>
      </ul>
  </div>

  <div class="op-grid-page--grid-container" *ngIf="isTurboFrameSidebarEnabled()">
    <div class="op-grid-page--main-content">
      <grid *ngIf="grid" [grid]="grid"></grid>
    </div>
    <div class="op-grid-page--sidebar">
      <turbo-frame
        *ngIf="isLifeCyclesSidebarEnabled()"
        [src]="lifeCyclesSidebarSrc()"
        [id]="lifeCyclesSidebarId()"
      >
        <op-content-loader viewBox="0 0 100 100">
          <svg:rect x="0" y="0" width="70" height="5" rx="1" />

          <svg:rect x="75" y="0" width="25" height="5" rx="1" />

          <svg:rect x="0" y="10" width="100" height="8" rx="1" />

          <svg:rect x="0" y="25" width="100" height="12" rx="1" />
        </op-content-loader>
      </turbo-frame>

      <turbo-frame
        *ngIf="isCustomFieldsSidebarEnabled()"
        [src]="projectCustomFieldsSidebarSrc()"
        [id]="projectCustomFieldsSidebarId()"
      >
        <op-content-loader viewBox="0 0 100 100">
          <svg:rect x="0" y="0" width="70" height="5" rx="1" />

          <svg:rect x="75" y="0" width="25" height="5" rx="1" />

          <svg:rect x="0" y="10" width="100" height="8" rx="1" />

          <svg:rect x="0" y="25" width="100" height="12" rx="1" />
        </op-content-loader>
      </turbo-frame>
    </div>
  </div>

  <div *ngIf="!isTurboFrameSidebarEnabled()">
    <grid *ngIf="grid" [grid]="grid"></grid>
  </div>

</div>
