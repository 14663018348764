<button
  class="button toolbar-icon"
  data-test-selector="op-wp-reminder-button"
  [attr.title]="buttonTitle"
  (click)="openModal()"
>
  <ng-container *ngIf="(hasReminder$ | async) as reminder; else noReminder">
    <svg op-alarm-set-icon class="button--icon" size="small"></svg>
  </ng-container>
  <ng-template #noReminder>
    <svg op-alarm-icon class="button--icon" size="small"></svg>
  </ng-template>
</button>
