<op-in-app-notification-date-alert
  *ngIf="hasDateAlert"
  [aggregatedNotifications]="dateAlerts">
</op-in-app-notification-date-alert>
<op-in-app-notification-relative-time
  *ngIf="!hasDateAlert"
  [notification]="reminderAlert"
  [hasActorByLine]="false">
</op-in-app-notification-relative-time>

<span
  class="op-ian-reminder-alert--note"
  [textContent]="reminderNote"
></span>
